import React from 'react'
import Layout from '../layouts'
// import ShopCategory from "../components/ecwid/category"

const UsefulPage = ({ data }) => (
	<Layout
		pathname="/useful-links-to-other-web-sites/"
		head={{
			name: 'Useful Links To Other Web Sites',
			meta: {
				description:
					'Useful Links To Other Web Sites Boat Kits & Plans Glen-L marine designs A great source of plans & kits for sailboats, kayaks, canoes, rowboats, power boats--you name it.',
			},
		}}
	>
		<div className="container py-5">
			<div className="row">
				{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
				<div className="col-md-8 shop-items mx-auto">
					<div className="page-content">
						<h4 className="section-heading page-mt">
							Useful Links To Other Web Sites
						</h4>
						<h5>Boat Kits & Plans</h5>
						<p>
							Glen-L marine designs A great source of plans & kits for
							sailboats, kayaks, canoes, rowboats, power boats--you name it. In
							business since 1953.
						</p>
						<h5>Fishing Info</h5>
						<p>
							<a href="https://www.cyberangler.com/" title="CyberAngler">
								CyberAngler{' '}
							</a>
							Information on fishing from around the world.
						</p>
						<p>
							<a
								href="http://www.texasgulfcoastfishing.com/"
								title="Texas Gulf Coast Fishing"
							>
								Texas Gulf Coast Fishing
							</a>
							&nbsp;All kinds of nifty coastal fishing information.
						</p>
						<p>
							<a
								href="http://www.walleyecharter.com/index.html"
								title="Walleye Charters"
							>
								Walleye Charters:
							</a>
							&nbsp;Lake Erie Walleye fishing aboard Capt. Keith Unkefer&#39;s
							41 Viking. He&#39;s a good guy & catches lots of fish.
						</p>
						<h5>Products & Services</h5>
						<p>
							<strong>
								<a href="http://www.saltservice.net/" title="S.A.L.T">
									S.A.L.T.
								</a>
							</strong>
						</p>
						<p>
							Sea, Air, Land Technologies. Excellent resource for on-board
							systems maintenance, repair and replacement, located in Marathon.
						</p>
						<p>
							<strong>
								<a
									href="http://www.pontoonspecialists.com/"
									title="Pontoon Specialists"
								>
									Pontoon Specialists
								</a>
							</strong>
						</p>
						<p>
							Boat Carpet and Furniture, Pontoon CoversOffers pontoon parts and
							accessories including bimini tops, canopies, covers, railings and
							seats. They have recarpeting/redecking kits that are also worth
							checking out.
						</p>
						<h4 style={{ marginTop: '50px' }}>Link To Us, It&#39;s Easy.</h4>
						<p>
							Down below, you&#39;ll find a few &#34;Buck Woodcraft&#34;
							examples you can use in linking to us. You can either just
							cut-and-paste the text onto your links page, or insert the HTML
							code into your own code.
						</p>
						<p>Either way, Thanks very much for spreading the word.</p>
						<h6>Option 1:</h6>
						<small>
							<a href="https://www.buckwoodcraft.com/" title="Buck Woodcraft">
								Buck Woodcraft
							</a>
						</small>
						<p>
							<small>
								Marine lumber, plywood, plastics & composites, plus standard and
								custom products for boaters and fishermen.
							</small>
						</p>
						<div style={{ backgroundColor: '#e5e5e5', padding: '10px' }}>
							<span style={{ fontSize: '14px' }}>
								&#60;p align="center"&#62;&#60;font
								size="4"&#62;&#60;b&#62;&#60;a
								href="https://www.buckwoodcraft.com/"&#62;Buck
								Woodcraft&#60;/a&#62; &#60;/b&#62;&#60;/font&#62;&#60;/p&#62;
								<br /> &#60;p align="center"&#62;&#60;font size="2"&#62;Marine
								lumber, plywood, plastics &amp; composites, plus standard &amp;
								custom products for boaters and
								fishermen.&#60;/font&#62;&#60;/p&#62; &#60;p
								align="center"&#62;&#60;/p&#62;
							</span>
						</div>
						<h6 style={{ marginTop: '20px' }}>Option 2:</h6>
						<small>
							<a href="https://www.buckwoodcraft.com/" title="Buck Woodcraft">
								Buck Woodcraft
							</a>{' '}
							-- Marine lumber, plywood, plastics & composites, plus standard &
							custom products for boaters.
						</small>
						<p>
							<div style={{ backgroundColor: '#e5e5e5', padding: '10px' }}>
								<span style={{ fontSize: '14px' }}>
									&#60;div align="left"&#62; &#60;pre&#62;&#60;font
									face="Trebuchet MS"&#62;&#60;big&#62;&#60;font
									size="3"&#62;&#60;a
									href="https://www.buckwoodcraft.com/"&#62;Buck
									Woodcraft&#60;/a&#62;&#60;/font&#62;&#60;font size="4"&#62; --
									&#60;/font&#62;&#60;/big&#62;Marine lumber, plywood, plastics
									&amp; composites, plus standard &amp; custom products for
									boaters.&#60;/font&#62;&#60;/pre&#62; &#60;/div&#62;
								</span>
							</div>
						</p>
						<h6 style={{ marginTop: '20px' }}>Option 3:</h6>
						<small>
							<a href="https://www.buckwoodcraft.com/" title="Buck Woodcraft">
								Buck Woodcraft
							</a>{' '}
							-- Marine lumber, plywood & plastics, plus standard & custom
							products for boaters.
						</small>
						<p>
							<div style={{ backgroundColor: '#e5e5e5', padding: '10px' }}>
								<span style={{ fontSize: '14px' }}>
									&#60;div align="left"&#62; &#60;pre&#62;&#60;font
									face="Trebuchet MS"&#62;&#60;big&#62;&#60;b&#62;&#60;font
									size="2"&#62;&#60;a
									href="https://www.buckwoodcraft.com/"&#62;Buck
									Woodcraft&#60;/a&#62; &#60;/font&#62;&#60;/b&#62;&#60;font
									size="4"&#62;-- &#60;/font&#62;&#60;/big&#62;Marine lumber,
									plywood &amp; plastics, plus standard &amp; custom products
									for boaters.&#60;/font&#62;&#60;/pre&#62; &#60;/div&#62;
								</span>
							</div>
						</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default UsefulPage

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
